/**
 *
 * @param {unknown} obj object to be checked
 * @returns {boolean} true if obj is an object
 */
export function isObject(obj) {
  // checks that an object is indeed an object and not an array or null
  return obj !== null && typeof obj === 'object' && Array.isArray(obj) === false;
}
/**
 * isClientSide
 * checks if the current environment is client side
 * @returns {boolean} Returns true if window is not undefined
 */
export function isClientSide() {
  return typeof window !== 'undefined';
}

// no se puede usar hover:hover solo porque los samsung detectan incorrectamente el input. https://issues.chromium.org/issues/41445959
export function canUserHover() {
  if (typeof window === 'undefined') return false;
  return window.matchMedia('(pointer: fine) and (hover: hover)').matches;
}
